<template>
  <div>
    <h2 class="section-title section-title--bot-indent" v-if="data.title">{{ data.title }}</h2>
    <div class="values-list" v-if="data.list && data.list.length">
      <div class="value-preview" v-for="item in data.list" :key="item.title">
        <div class="value-preview__img-box">
          <component :is="'v-icon-' + item.icon" class="value-preview__img"></component>
        </div>
        <div class="value-preview__data value-preview-data">
          <p class="value-preview__title" v-if="item.title">{{ item.title }}</p>
          <p class="value-preview__description" v-if="item.description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Values",
  props: {
    data: Object
  },
  components: {
    'v-icon-value-360': () => import('@/assets/icons/value-360.svg'),
    'v-icon-value-approach': () => import('@/assets/icons/value-approach.svg'),
    'v-icon-value-partnership': () => import('@/assets/icons/value-partnership.svg'),
  }
}
</script>

<style lang="scss">
.value-preview {
  display: flex;
  padding: 70px 0 70px 80px;
  border-top: 1px solid #f1f1f1;

  @media (max-width: $viewport-tablet-small) {
    padding: 30px;
  }

  @media (max-width: $viewport-mobile) {
    flex-direction: column;
  }
}

.value-preview__img {
  width: 200px;
  height: 200px;
  margin-right: 170px;
  margin-top: -10px;

  @media (max-width: $viewport-tablet) {
    margin-right: 85px;
  }

  @media (max-width: $viewport-tablet-small) {
    width: 130px;
    height: 130px;
    margin-top: 0;
    margin-right: 50px;
  }

  @media (max-width: $viewport-mobile) {
    width: 70px;
    height: 70px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.value-preview__title {
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;

  @media (max-width: $viewport-tablet) {
    font-size: 1.4375rem;
  }

}

.value-preview__description {
  margin-top: 20px;
  font-size: 1.4rem;
  line-height: 1.80952381;

  @media (max-width: $viewport-tablet) {
    font-size: 1.125rem;
  }

}
</style>
