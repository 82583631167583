<template>
  <div>
    <h2 class="section-title" v-if="data.title">{{ data.title }}</h2>
    <p class="section-description-row" v-if="data.subTitle">{{ data.subTitle }}</p>
    <div class="easy-to-start-list" v-if="data.list && data.list.length">
      <div class="easy-to-start-preview" v-for="item in data.list" :key="item.title" @click.stop="goToThirdStep(item)">
        <span class="easy-to-start-preview__title">
          {{ item.title }}
          <component v-if="item.icon" :is="'v-icon-' + item.icon" class="easy-to-start-preview__title-img" :class="'easy-to-start-preview__title-img--' + item.iconMod"></component>
        </span>
        <span class="easy-to-start-preview__description" v-if="item.description">{{ item.description }}</span>
        <span class="easy-to-start-preview__link" v-if="item.linkText">{{ item.linkText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main.js'
export default {
  name: "Easy-to-start",
  props: {
    data: Object,
  },
  components: {
    'v-icon-ets-cloud': () => import('@/assets/icons/ets-cloud.svg'),
    'v-icon-ets-code': () => import('@/assets/icons/ets-code.svg'),
    'v-icon-ets-design': () => import('@/assets/icons/ets-design.svg'),
    'v-icon-ets-estimation': () => import('@/assets/icons/ets-estimation.svg'),
    'v-icon-ets-qa': () => import('@/assets/icons/ets-qa.svg'),
    'v-icon-ets-sprint': () => import('@/assets/icons/ets-sprint.svg'),
    'v-icon-ets-maintenance': () => import('@/assets/icons/ets-maintenance.svg'),
  },
  methods: {
    goToThirdStep(item) {
      eventBus.$emit('open-get-quote-modal', item.steps);
      this.pushGa();
    },
    pushGa() {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'quote'
      })
    }
  }
}
</script>

<style lang="scss">
.easy-to-start-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.easy-to-start-preview {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 40px 40px 30px;
  color: #000;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;
  cursor: pointer;

  @media (max-width: $viewport-tablet) {
    width: 33.333%;
    padding: 35px 50px 25px 30px;
  }

  @media (max-width: $viewport-tablet-small) {
    width: 50%;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 1px;
    background-color: #fff;
    box-sizing: border-box;
  }

  &:hover {
    background-color: #fff;

    .easy-to-start-preview__link {
      opacity: 1;
    }
  }
}

.easy-to-start-preview__title {
  position: relative;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;

  @media (max-width: $viewport-mobile) {
    font-size: 1.75rem;
  }
}

.easy-to-start-preview__title-img {
  position: absolute;
  margin-left: 10px;

  &--estimation {
    width: 30px;
    height: 30px;

    @media (max-width: $viewport-mobile) {
      width: 25px;
      height: 25px;
    }
  }

  &--qa {
    width: 40px;
    height: 40px;

    @media (max-width: $viewport-mobile) {
      width: 35px;
      height: 35px;
    }
  }

  &--code {
    width: 37px;
    height: 37px;

    @media (max-width: $viewport-mobile) {
      width: 32px;
      height: 32px;
    }
  }

  &--cloud {
    width: 30px;
    height: 30px;

    @media (max-width: $viewport-mobile) {
      width: 25px;
      height: 25px;
    }
  }

  &--sprint {
    width: 35px;
    height: 35px;

    @media (max-width: $viewport-mobile) {
      width: 28px;
      height: 28px;
    }
  }

  &--maintenance {
    width: 37px;
    height: 37px;

    @media (max-width: $viewport-mobile) {
      width: 30px;
      height: 30px;
    }
  }

  &--design {
    width: 31px;
    height: 31px;

    @media (max-width: $viewport-mobile) {
      width: 26px;
      height: 26px;
    }
  }
}

.easy-to-start-preview__description {
  display: block;
  margin: 15px 0 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.166666667;

  @media (max-width: $viewport-mobile) {
    margin: 10px 0;
  }
}

.easy-to-start-preview__link {
  display: inline-block;
  position: relative;
  padding: 10px 65px 10px 0;
  margin-top: auto;
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}
</style>
