<template>
  <div>
    <v-slider
        :ref-name="'technologiesSlider'"
        :slides="slides"
        :slider-class="'technologies-slider'"
        :slide-class="'technology-stack'"
        :options="sliderOptions"
        class="services-slider-container">
      <template v-slot:slide="slideContent">
        <div class="technology-stack__title" v-if="slideContent.slide.title">{{ slideContent.slide.title }}</div>
        <div class="technology-stack__data">

          <div class="technology-stack__cell" v-for="(category, index) in slideContent.slide.list" :key="index">
            <p class="technology-stack-list-title" v-if="category.title">{{ category.title }}</p>
            <ul class="technology-stack-list" :class="{'technology-stack-list--sublist': category.title}">
              <li class="technology-stack-list__item" v-for="(language, index) in category.list" :key="index">
                <span class="technology-stack-list__item-title" v-if="language.title">{{ language.title }}</span>
                <ul class="technology-stack-list" :class="{'technology-stack-list--sublist': language.title}">
                  <li class="technology-stack-list__item" v-for="item in language.list" :key="item">{{ item }}</li>
                </ul>
              </li>
            </ul>
          </div>

        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider/Slider'

export default {
  name: "Technologies-slider",
  data() {
    return {
      sliderOptions: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: 0,
        loop: true,
        autoHeight: false,
        grabCursor: true,
        watchSlidesVisibility: false,
      },
      slides: [
        {
          title: 'QA',
          list: [
            {
              title: '',
              list: [
                {
                  title: '',
                  list: ['Python', 'JAVA', 'Selenium', 'Jenkins', 'Bamboo', 'GitlubCI', 'Jmeter', 'LoadImpact', 'Postman', 'Sonar Cube', 'Zabbix', 'JS', 'NewRelic', 'Teamcity', 'СircleCI', 'Rollbar']
                }
              ]
            }
          ]

        },
        {
          title: 'DB',
          list: [
            {
              title: '',
              list: [
                {
                  title: '',
                  list: ['PostgreSQL', 'MySQL', 'MariaDB', 'Elasticsearch', 'Oracle DB', 'SQLCipher', 'MSSQL']
                }
              ]
            },
            {
              title: 'NoSql',
              list: [
                {
                  title: '',
                  list: ['Redis', 'MongoDB', 'Zookeeper', 'Firebase', 'Couchbase', 'InfluxDB']
                }
              ]
            }
          ]
        },
        {
          title: 'Backend',
          list: [
            {
              title: 'NodeJS',
              list: [
                {
                  title: '',
                  list: ['Express.js', 'Nest.js', 'Meteor.js', 'Adonis.js', 'Koa.js']
                }
              ]
            },
            {
              title: 'PHP',
              list: [
                {
                  title: '',
                  list: ['Laravel', 'Symfony', 'YII2', 'Magento', 'Bitrix', 'Opencart']
                }
              ]
            },
            {
              title: 'Ruby',
              list: [
                {
                  title: '',
                  list: ['Ruby on Rails', 'Hanami', 'Sinatra']
                }
              ]
            },
            {
              title: 'JVM',
              list: [
                {
                  title: 'Java',
                  list: ['Spring', 'Grails', 'Vaadin']
                },
                {
                  title: 'Kotlin',
                  list: ['Spring', 'Vaadin']
                },
                {
                  title: 'Groovy',
                  list: ['Spring', 'Grails']
                }
              ]
            }
          ]
        },
        {
          title: 'DevOps',
          list: [
            {
              title: '',
              list: [
                {
                  title: '',
                  list: ['AWS', 'Google Cloud Platform', 'Microsoft Azure', 'DigitalOcean']
                }
              ]
            },
            {
              title: 'CM&O',
              list: [
                {
                  title: '',
                  list: ['Jenkins', 'Bamboo', 'GithubCI', 'Zabbix', 'NewRellic', 'CircleCI', 'Rollbar']
                }
              ]
            }
          ]
        },
        {
          title: 'Architect',
          list: [
            {
              title: '',
              list: [
                {
                  title: '',
                  list: ['Enterprise Architect', 'System/Solution Architect', 'Technology Architect']
                }
              ]
            }
          ]
        },
        {
          title: 'Frontend',
          list: [
            {
              title: '',
              list: [
                {
                  title: '',
                  list: ['ReactJS', 'VueJS', 'AngularJS', 'Polymer', 'HTML/CSS, WebPack']
                }
              ]
            }
          ]
        }
      ]
    }
  },
  components: {
    'v-slider': Slider
  }
}
</script>

<style lang="scss">
.technologies-slider {
  //width: 2200px;
  overflow: hidden;
  overflow-x: scroll;
  background: #fafafa;
}

.technology-stack {
  position: relative;
  float: left;
  //margin-right: 3px;
  &.swiper-slide {
    width: auto;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 10000px;
  }

  &:before {
    right: 0px;
    width: 3px;
    background-color: #fff;
  }

  &:after {
    right: 1px;
    width: 1px;
    background-color: #ccc;
  }

}

.technology-stack__title {
  width: 100%;
  margin-bottom: 3px;
  padding: 20px 30px;
  background: #f1f1f1;
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.461538462;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.4375rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 1.25rem;
  }
}

.technology-stack__data {
  display: flex;

  @media (max-width: $viewport-tablet-small) {
    max-width: 400px;
    flex-wrap: wrap;
  }

  @media (max-width: $viewport-mobile) {
    max-width: 200px;
    flex-wrap: wrap;
  }
}

.technology-stack__cell {
  position: relative;
  width: 200px;
  padding: 20px 30px;
  box-sizing: border-box;

  @media (max-width: $viewport-mobile) {
    padding: 20px 30px 0px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 10000px;
    right: 0px;
    width: 2px;
    background-color: #fff;
  }
}

.technology-stack-list {
  &--sublist > {

    .technology-stack-list__item {
      padding-left: 20px;
      border-left: 1px solid #E1E1E1;
    }
  }
}

.technology-stack-list-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.3125rem;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.125rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 1rem;
  }
}

.technology-stack-list__item {
  font-size: 1.125rem;
  line-height: 1.42;
  padding: 5px 0;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 0.875rem;
  }
}

.technology-stack-list__item-title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
</style>
