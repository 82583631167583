<template>
  <div class="services-list">
    <div class="services-preview" v-for="item in data" :key="item.title">
      <component :is="'v-icon-' + item.icon" class="services-preview__img"></component>
      <span class="services-preview__title" v-if="item.title">{{ item.title }}</span>
      <span class="services-preview__description" v-if="item.description">{{ item.description }}</span>
    </div>
  </div>
</template>

<script>
import IconServicesProduct from "@/assets/icons/services-product.svg"
import IconServicesManaged from "@/assets/icons/services-managed.svg"
import IconServicesDedicated from "@/assets/icons/services-dedicated.svg"
import IconServicesRd from "@/assets/icons/services-rd.svg"

export default {
  name: "Services-list",
  props: {
    data: Array
  },
  components: {
    'v-icon-services-product': IconServicesProduct,
    'v-icon-services-managed': IconServicesManaged,
    'v-icon-services-dedicated': IconServicesDedicated,
    'v-icon-services-rd': IconServicesRd,
  }
}
</script>

<style lang="scss">
.services-list {
  display: flex;
  flex-wrap: wrap;
}

.services-preview {
  display: block;
  width: 50%;
  padding: 45px 45px 40px;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: #fff;

    .services-slide__link {
      opacity: 1;
    }
  }

  @media (max-width: $viewport-tablet) {
    padding: 35px 35px 30px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 1px;
    padding: 30px 30px 20px;
    background: #fff;
    border-left: 1px solid #F1F1F1;
    box-sizing: border-box;
  }
}

.services-preview__img {
  width: 138px;
  height: 138px;
  margin-bottom: 115px;

  @media (max-width: $viewport-tablet) {
    width: 92px;
    height: 92px;
    margin-bottom: 50px;
  }

  @media (max-width: $viewport-mobile) {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
}

.services-preview__title {
  display: block;
  margin-bottom: 15px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.75rem;
  }
}

.services-preview__description {
  display: block;
  margin-bottom: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.714285714;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    line-height: 1.8;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 30px;
  }
}

.services-preview__link {
  display: inline-block;
  padding: 10px 65px 10px 0;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  color: #000000;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 13px;
    height: 10px;
    background: url('/sprite.svg#arrow-right');
    transform: translateY(-50%);
  }
}
</style>
