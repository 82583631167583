<template>
  <div>
    <div class="wrapper">
      <h2 class="section-title section-title--bot-indent section-title--with-slider">{{ title }}</h2>
    </div>c
    <div class="technologies-slider-container">
      <v-technologies-slider></v-technologies-slider>
    </div>
  </div>
</template>

<script>
// Components
import TechnologiesSlider from '@/components/Slider/Technologies-slider'

export default {
  name: "Technologies",
  data() {
    return {
      title: 'Technology stack',
    }
  },
  components: {
    'v-technologies-slider': TechnologiesSlider
  }
}
</script>

<style lang="scss">
.technologies-slider-container {
  position: relative;
}
</style>
