<template>
  <div>
    <h2 class="section-title section-title--with-slider section-title--bot-indent" v-if="data.title">{{ data.title }}</h2>
    <div class="pricing-models-list" v-if="data.list">
      <a :href="item.link" class="pricing-model-preview" v-for="item in data.list" :key="item.title">
        <span class="pricing-model-preview__title">
          {{ item.title }}
          <component :is="'v-icon-' + item.icon" v-if="item.icon" class="pricing-model-preview__title-img" :class="'pricing-model-preview__title-img--' + item.iconMod"></component>
        </span>
        <span class="pricing-model-preview__description" v-if="item.description">{{ item.description }}</span>
        <span class="pricing-model-preview__link">{{ item.linkText }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing-model",
  props: {
    data: Object
  },
  components: {
    'v-icon-pm-time': () => import('@/assets/icons/pm-time.svg'),
    'v-icon-pm-cost': () => import('@/assets/icons/pm-cost.svg'),
    'v-icon-pm-fixed': () => import('@/assets/icons/pm-fixed.svg'),
    'v-icon-pm-combine': () => import('@/assets/icons/pm-combine.svg'),
  }
}
</script>

<style lang="scss">
.pricing-models-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: $viewport-tablet) {
  }

  @media (max-width: $viewport-mobile) {
  }
}

.pricing-model-preview {
  width: 50%;
  padding: 65px 90px 60px;
  color: #000;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    padding: 35px 50px 25px 30px;
  }

  @media (max-width: $viewport-tablet-small) {
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 1px;
    background-color: #F1F1F1;
    box-sizing: border-box;
  }

  &:hover {
    background-color: #F1F1F1;

    .pricing-model-preview__link {
      opacity: 1;
    }
  }
}

.pricing-model-preview__title {
  position: relative;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;

  @media (max-width: $viewport-mobile) {
    font-size: 1.75rem;
  }
}

.pricing-model-preview__title-img {
  position: absolute;
  right: -45px;
  bottom: 6px;
  width: 34px;
  height: 34px;

  @media (max-width: $viewport-mobile) {
    width: 27px;
    height: 27px;
    right: -35px;
    bottom: 5px;
  }

  &--cost {
    right: -90px;
    width: 78px;

    @media (max-width: $viewport-mobile) {
      width: 61px;
      height: 27px;
      right: -70px;
    }
  }
}

.pricing-model-preview__description {
  display: block;
  margin: 30px 0 95px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.714285714;

  @media (max-width: $viewport-mobile) {
    margin: 20px 0 30px;
  }
}

.pricing-model-preview__link {
  display: inline-block;
  position: relative;
  padding: 10px 65px 10px 0;
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}
</style>
