<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual"></v-main-info>
      </div>
    </section>
    <section class="section-box section-box--highlighted">
      <div class="wrapper">
        <v-services-list :data="servicesList"></v-services-list>
      </div>
    </section>
    <section class="section-box section-box--highlighted">
      <div class="wrapper">
        <v-easy-to-start :data="easyToStart"></v-easy-to-start>
      </div>
    </section>
    <section class="section-box section-box--no-bot-padding">
      <v-technologies></v-technologies>
    </section>
    <section class="section-box section-box--no-bot-padding">
      <div class="wrapper">
        <v-pricing-model :data="pricingModel"></v-pricing-model>
      </div>
    </section>
    <section class="section-box">
      <div class="wrapper">
        <v-values :data="valuesData"></v-values>
      </div>
    </section>
  </div>
</template>

<script>
import MainInfo from '@/components/layout/Main-info/Main-info'
import ServicesList from '@/components/layout/Services/Services-list'
import EasyToStart from '@/components/layout/Easy-to-start/Easy-to-start'
import PricingModel from '@/components/layout/Pricing-model/Pricing-model'
import Values from "@/components/layout/Values/Values";
import Technologies from "@/components/layout/Technologies/Technologies";

export default {
  name: "Services",
  metaInfo: {
    title: 'Outsourcing service – ITernal group'
  },
  data() {
    return {
      mainInfoData: {
        title: 'Our <span class="main-info-title__highlighted">360°</span> services',
        subTitle: 'Large successful enterprises and booming startups work with us. Since each client is unique and has different needs, we offer a wide range of software development services that unlock the potential of our clients and fuel their growth.',
      },
      servicesList: [
        {
          icon: 'services-product',
          title: 'Product development',
          description: 'Bring your idea and let us analyze, build, design, and release a top-notch custom software to market.'
        },
        {
          icon: 'services-managed',
          title: 'Managed service',
          description: 'Focus on your business and save a lot of resources with a whole range of recurring IT services.'
        },
        {
          icon: 'services-dedicated',
          title: 'Dedicated team',
          description: 'Expand your development capabilities and tap into new tech expertise with a team working just for you.'
        },
        {
          icon: 'services-rd',
          title: 'R&D',
          description: 'Get access to dedicated R&D centers and the largest CEE tech talent pool at a cost that is up to 60% lower compared to the EU and US.'
        }
      ],
      easyToStart: {
        title: 'Easy-to-start services',
        subTitle: 'ITernal offers software development services that are tailored to the specificity of the project. Start small with us and grow big.',
        list: [
          {
            title: 'Project estimation',
            id: 'project_estimation',
            steps: {
              project_type: 'scratch',
              project_services: ['design_requirements']
            },
            iconMod: 'estimation',
            icon: 'ets-estimation',
            description: 'Estimate cost and time before the start of a project.',
            linkText: 'Estimate a project',
            link: '#'
          },
          {
            title: 'QA analysis',
            id: 'qa_analysis',
            steps: {
              project_type: 'existing',
              project_services: ['other']
            },
            iconMod: 'qa',
            icon: 'ets-qa',
            description: 'Evaluate the quality and identify gaps in your product.',
            linkText: 'Discuss cooperation',
            link: '#'
          },
          {
            title: 'Code quality analysis',
            id: 'code_quality_analysis',
            steps: {
              project_type: 'existing',
              project_services: ['web_development', 'mobile_development', 'other']
            },
            iconMod: 'code',
            icon: 'ets-code',
            description: 'Determine errors that may cause risks and fatal results.',
            linkText: 'Estimate a project',
            link: '#'
          },
          {
            title: 'Cloud migration',
            id: 'cloud_migration',
            steps: {
              project_type: 'existing',
              project_services: ['expert_consulting']
            },
            iconMod: 'cloud',
            icon: 'ets-cloud',
            description: 'Find the cloud model and type that fits your needs.',
            linkText: 'Estimate a project',
            link: '#'
          },
          {
            title: 'Test sprint',
            id: 'test_sprint',
            steps: {
              project_type: 'scratch',
              project_services: ['web_development', 'mobile_development', 'other']
            },
            iconMod: 'sprint',
            icon: 'ets-sprint',
            description: 'Get a ready-made MVP with a selected set of features in 2 weeks.',
            linkText: 'Estimate test sprint',
            link: '#'
          },
          {
            title: 'Maintenance',
            id: 'maintenance',
            steps: {
              project_type: 'scratch',
              project_services: ['web_development', 'mobile_development', 'other']
            },
            iconMod: 'maintenance',
            icon: 'ets-maintenance',
            description: 'Redesign a legacy software to modernize and make it run like clockwork.',
            linkText: 'Discuss cooperation',
            link: '#'
          },
          {
            title: 'Design review',
            id: 'design_review',
            steps: {
              project_type: 'existing',
              project_services: ['ui_ux_design']
            },
            iconMod: 'design',
            icon: 'ets-design',
            description: 'Identify if the design of existing software meets business needs.',
            linkText: 'Estimate a project',
            link: '#'
          }
        ]
      },
      pricingModel: {
        title: 'Choose a pricing model that suits your business needs',
        list: [
          {
            title: 'Time and material',
            iconMod: 'time',
            icon: 'pm-time',
            description: 'The Time & Material model is used if there is no whole vision of the final product and its implementation specifics and if it is impossible to divide it into several smaller stages. This option is fully negotiable and can use an hourly, daily, weekly or monthly rate for the amount of work, tasks, resources, materials, or other expenses that were applied in the development process.',
            linkText: 'Request rates',
            link: '#'
          },
          {
            title: 'Cost plus',
            iconMod: 'cost',
            icon: 'pm-cost',
            description: 'The Cost Plus pricing involves adding a monthly service fee to the employee gross salary. The minimum period of collaboration for the Cost Plus pricing is 12 month. If you need a tailored offer just let us know.',
            linkText: 'Request rates',
            link: '#'
          },
          {
            title: 'Fixed price',
            iconMod: 'fixed',
            icon: 'pm-fixed',
            description: 'The Fixed Price model assumes that the total budget on the project is approved before development starts and remains unchanged. Also, before development starts the exact deadline must be approved. The risks for the late execution of works will be borne by the contractor. On the one hand, this model may seem convenient for you, as a customer, as you know how much time and money the project development requires before the work starts.',
            linkText: 'Request rates',
            link: '#'
          },
          {
            title: 'Combine and switch',
            iconMod: 'combine',
            icon: 'pm-combine',
            description: 'We can agree on any combination of business models mentioned above. As we provide transparent cost reports, you can track expenses in real-time and switch to a specific model that lets you stay within your budget.',
            linkText: 'Discuss cooperation',
            link: '#'
          }
        ]
      },
      valuesData: {
        title: 'Our key values',
        list: [
          {
            icon: 'value-360',
            title: 'All-in-one IT vendor',
            description: 'We offer 360° services to enterprise, SMB, and startups that want to grow exponentially. Our clients can rely on our 16-year expertise and willingness to find the best solution that brings their ideas to life.'
          },
          {
            icon: 'value-approach',
            title: 'Unique approach',
            description: 'Our approach bridges clients’ needs and our flexibility. We offer different sets of services and pricing models to select from. Also, we can work out a tailored option that perfectly fits business needs within the budget.'
          },
          {
            icon: 'value-partnership',
            title: 'Transparent partnership',
            description: 'We believe that long-term relations with clients are built on open communication. That’s why we are honest in our recommendations and transparent to the last figure in cost reports.'
          }
        ]
      }
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-services-list': ServicesList,
    'v-easy-to-start': EasyToStart,
    'v-pricing-model': PricingModel,
    'v-values': Values,
    'v-technologies': Technologies
  }
}
</script>

<style lang="scss">

</style>
